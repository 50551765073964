import * as React from "react";
import Modal from "../../Utils/Modal";
import { IExperience, IModalInfo } from "../../interfaces/Index";
import Block from "../../Utils/Block";

const HOBIcon = require("../../../images/experience/HOB/HOB.png");
const HOBImageOne = require("../../../images/experience/HOB/image1.png");
const HOBImageTwo = require("../../../images/experience/HOB/image3.png");
const HOBImageThree = require("../../../images/experience/HOB/image4.png");

const BBIcon = require("../../../images/experience/BB/BB.jpg");
const BBImageOne = require("../../../images/experience/BB/BB-Intro.png");
const BBImageTwo = require("../../../images/experience/BB/BBIDEA.png");
const BBImageThree = require("../../../images/experience/BB/BBOPTIONS.png");

console.log(BBImageOne.default)

const Experience = (props: IExperience) => {
   const {setModalInfo, modalInfo} = props;

   return (
      <div id="Experience" className="content-child">
            <div>
               <div className="main-title">WORK</div>
               <div className="block-container">
                  <Block
                     id="Hause of Brands"
                     website="https://www.hauseofbrands.com"
                     img={HOBIcon.default}
                     imgOne={HOBImageOne.default}
                     imgTwo={HOBImageTwo.default}
                     imgThree={HOBImageThree.default}
                     vision={"Streamline wholeselling dealership vehicles to maximize speed, efficiency, and profits."}
                     tech={["React", "NodeJS", "Express.js", "TypeScript", "MongoDB", "Material-UI"]}
                     desc="Hause of Brands is a 9-employee automotive wholesale remarketing company that
                     specializes in providing services to vehicle dealerships."
                     setModalInfo={setModalInfo}
                  />
                  <Block
                     id="Brooks and Bridges"
                     website="https://www.brooksbridges.com/"
                     img={BBIcon.default}
                     imgOne={BBImageOne.default}
                     imgTwo={BBImageTwo.default}
                     imgThree={BBImageThree.default}
                     vision={"Bringing Luxury Tableware and Dinnerware services online."}
                     tech={["React", "NodeJS", "TypeScript", "Pug", "Stripe.js", "MongoDB"]}
                     desc="Brooks and Bridges is a company with a goal in providing budget-friendly party supplies
                     for big events and weddings."
                     setModalInfo={setModalInfo}
                  />
               </div>
         </div>
      </div>
   );
};

export default Experience;
