import * as React from "react";
import Card from "../Utils/Card";

const Projects = () => {
   return (
      <div className="content-child" id="Projects">
         <div className="main-title">PROJECTS</div>
         <div className="card-container">
            <Card
               title="Pokedex-Picker"
               tech={[
                  "React",
                  "TypeScript",
                  "Chakra-UI",
                  "SQL",
                  "NodeJS",
                  "Express.js",
               ]}
               desc="Performed SQL queries to create database schema and populate database with interaction from React components. Created  RESTful API with six endpoints to bridge front-end and database using ExpressJS."
               github="https://github.com/maherhalabi/pokedex-picker"
               website={null}
            />
            <Card
               title="Covid-Dashboard"
               tech={["React", "Axios", "JSON", "CSS"]}
               desc="Leveraged real-time data from John Hopkins University with APIs within NodeJS to React components providing 7-day averages, heat-map, and composed charts for either country or worldwide using front-end libraries."
               github="https://github.com/maherhalabi/covid-data-tracker"
               website={"http://another-coronavirus-tracker.s3-website-us-west-2.amazonaws.com/"}
            />
            <Card
               title="Postack"
               tech={["React", "Redux", "NodeJS"]}
               desc="Implemented CRUD operations using NodeJS and MongoDB for registered users' items from React front-end."
               github="https://github.com/maherhalabi/postack"
               website={null}
            />
            <Card
               title="Gradient Grove"
               tech={["Javascript", "HTML", "CSS"]}
               desc="Creates gradients by users choosing colors in various patterns and saving CSS cross-browser for UI
              design or backgrounds."
               github="https://github.com/maherhalabi/gradient-grove"
               website={null}
            />
            <Card
               title="Rally"
               tech={["Javascript", "HTML", "CSS"]}
               desc="Two-dimensional interactive Javascript game, complete with collision detection, AI behavior, and design principles."
               github="https://github.com/maherhalabi/rally"
               website={"https://maherhalabi.github.io/rally/"}
            />
         </div>
      </div>
   );
};

export default Projects;
