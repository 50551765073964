import React from "react";
import { ICard } from "../interfaces/Index";

const Card = (props: ICard) => {
   const { website, github, title, tech, desc } = props;
   return (
      <div className="card">
         <div>
            <div className="card-title">
               <div className="sub-title">{title}</div>
            </div>
            <div>
               <div className="card-tech">
                  {tech.map((i, key) => {
                     return (
                        <li key={key} className="little-detail">
                           {i}
                        </li>
                     );
                  })}
               </div>
            </div>
            {/* DESC*/}
            <div>
               <div className="paragraph">{desc}</div>
            </div>
         </div>
         <div>
            {/* LINKS*/}
            <div>
               <div className="card-links paragraph">
               <a href={github}>GITHUB</a>
               {!website ? null : <a href={website}>WEBSITE</a>}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Card;
