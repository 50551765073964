import * as React from "react";
import About from "./About";
import Contact from "./Contact";
import Experience from "./Experience/Index";
import Introduction from "./Introduction";
import { IContent } from "../interfaces/Index";

import { Waypoint } from "react-waypoint";
import Projects from "./Projects";

const Content = (props: IContent) => {
   const { setSectionVisit, setModalInfo, modalInfo } = props;

   const getSectionName = (string: string) => {
      return setSectionVisit(string);
   };

   return (
      <div className="content">
         <Waypoint
            onEnter={() => {
               getSectionName("Introduction");
            }}
         >
            <div>
               <Introduction />
            </div>
         </Waypoint>
         <Waypoint
            onEnter={() => {
               getSectionName("Contact");
            }}
         >
            <div>
               <Contact />
            </div>
         </Waypoint>
         <Waypoint
            onEnter={() => {
               getSectionName("Experience");
            }}
         >
            <div>
               <Experience setModalInfo={setModalInfo} modalInfo={modalInfo}/>
            </div>
         </Waypoint>
         <Waypoint
            onEnter={() => {
               getSectionName("Projects");
            }}
         >
            <div>
               <Projects />
            </div>
         </Waypoint>
      </div>
   );
};

export default Content;
