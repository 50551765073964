import * as React from "react"

const Introduction = () => {
  return (
    <div className="content-child" id="Introduction">
      <div className="sub-title">
        Hi, my name is
      </div>
      <div className="in-your-face">
        Maher Halabi
      </div>

      <div className="sub-title">
        A software engineer based in CA, USA with a passion for creating applications and solutions with the goal of assisting companies in accelerating their growth and outperforming their competitors. 
      </div>
    </div>
  )
}

export default Introduction
