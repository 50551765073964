import * as React from "react";
import "../styles/index.scss";
import Content from "./Content/Index";
import Header from "./Header/Index";
import { IModalInfo } from "./interfaces/Index";
import Sidebar from "./Sidebar/Index";
import Modal from "./Utils/Modal";
import SEO from "./SEO";
import logo from "../images/Log.png";

const App = () => {
   const [modalInfo, setModalInfo] = React.useState<IModalInfo>({
      open: false,
      website: "",
      img: "",
      imgOne: "",
      imgTwo: "",
      imgThree: "",
      company: "",
      vision: "",
      tech: [],
      desc: "",
   });

   const [sectionVisit, setSectionVisit] = React.useState("Introduction");
   return (
      <div className="container">
         <SEO logo={logo} />
         {modalInfo.open ? (
            <Modal open={modalInfo.open} setModalInfo={setModalInfo} modalInfo={modalInfo} />
         ) : (
            <div>
               {/* <Header /> */}
               <Content setSectionVisit={setSectionVisit} setModalInfo={setModalInfo} modalInfo={modalInfo} />
               {/* <Sidebar sectionVisit={sectionVisit} /> */}
            </div>
         )}
      </div>
   );
};

export default App;
