import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({ logo }: {logo: string}) {
   return (
      <Helmet>
         <title>{"Maher Halabi"}</title>
         <meta
            name="description"
            content={
               "A software engineer with a passion for creating applications and solutions with the goal of assisting companies in accelerating their growth and outperforming their competitors."
            }
         />
         <meta name="image" content={logo} />

         <meta property="og:title" content={"Maher Halabi"} />
         <meta property="og:type" content={"website"} />
         <meta
            property="og:description"
            content={
               "A software engineer with a passion for creating applications and solutions with the goal of assisting companies in accelerating their growth and outperforming their competitors."
            }
         />
         <meta property="og:image" content={logo} />

         <meta property="twitter:title" content={"Maher Halabi"} />
         <meta
            property="twitter:description"
            content={
               "A software engineer with a passion for creating applications and solutions with the goal of assisting companies in accelerating their growth and outperforming their competitors."
            }
         />
         <meta property="twitter:image" content={logo} />
         <meta name="twitter:card" content="summary_large_image" />

         <meta name="robots" content="index,follow" />
      </Helmet>
   );
}

// siteMetadata: {
//    title: `Maher Halabi`,
//    siteUrl: `https://www.maherhalabi.com`,
//    description: `Incoming computer science graduate with a strong product mentality and several years of experience leading growing companies’
//    online campaigns with user‑centric web apps.`,
//    author: `@maherhalabi`,
//    keywords: `software engineer, programmer, maher, halabi, maher halabi`,
//    image: `src/components/files/logo.png`
// },
