import React, { MouseEvent } from "react";
import { IModal } from "../interfaces/Index";

const Modal = (props: IModal) => {
   const { setModalInfo, modalInfo } = props;

   //DISPLAY VALUES FROM CARD (NEEDS PREVSTATE)

   const handleModalInfo = (e: MouseEvent<HTMLDivElement>) => {
      let companyName = e.currentTarget.id;
      setModalInfo((prevState) => ({
         open: !prevState.open,
         website: prevState.website,
         img: prevState.img,
         imgOne: prevState.imgOne,
         imgTwo: prevState.imgTwo,
         imgThree: prevState.imgThree,
         company: prevState.company,
         desc: prevState.desc,
         vision: prevState.vision,
         tech: prevState.tech
      }));
   };

   const handleClose = () => {
      setModalInfo((prevState) => ({
         ...prevState,
         open: !prevState.open,
      }));
   }

   return (
      <div
         className="modal"
         onClick={(e) => {
            handleModalInfo(e);
         }}
      >
         {/* <a onClick={handleClose} className="modal-close">
         <svg viewBox="0 0 20 20">
        <path fill="#FF0000" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
      </svg>
			</a> */}
         <img src={modalInfo.img} className="modal-img" />
         <a href={modalInfo.website} className="modal-title in-your-face">{modalInfo.company}</a>
         <div className="modal-details">
            <div className="modal-vision  main-title">{modalInfo.vision}</div>
            <div className="modal-tech-list paragraph">{modalInfo.tech.map((i) => {
               return <div className="modal-tech">{i}</div>
            })}
            </div>
         </div>
         <div className="modal-desc sub-title">{modalInfo.desc}</div>
         <img src={modalInfo.imgOne} className="modal-img-one" />
         <img src={modalInfo.imgTwo} className="modal-img-two" />
         <img src={modalInfo.imgThree} className="modal-img-three" />
      </div>
   );
};

export default Modal;
